
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IPlayer } from '../../../entities/player';
import { IPaginationResponse } from '../../../services/steamcord/paginationResponse';
import { getPlayers, SortDirection } from '@/services/steamcord';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';

interface IData {
  loading: boolean,
  currentPage: number,
  playersResponse?: IPaginationResponse<IPlayer>,
  searchTerm: string,
  sortDirection: SortDirection,
}

export default defineComponent({
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
    surroundingPages(): number[] | undefined {
      if (!this.playersResponse) {
        return undefined;
      }

      const pages = [this.currentPage];
      const { totalPages } = this.playersResponse;

      for (let i = 1; pages.length < 5; i += 1) {
        const isPageAfter = this.currentPage + i < totalPages;
        if (isPageAfter) {
          pages.push(this.currentPage + i);
        }

        const isPageBefore = this.currentPage - i >= 0;
        if (isPageBefore) {
          pages.unshift(this.currentPage - i);
        }

        if (!isPageAfter && !isPageBefore) {
          break;
        }
      }

      return pages;
    },
  },
  data(): IData {
    return {
      loading: true,
      currentPage: 0,
      playersResponse: undefined,
      searchTerm: '',
      sortDirection: 'desc',
    };
  },
  methods: {
    getAvatar(player: IPlayer): string | undefined {
      return (player.steamAccounts.find((steamAccount) => !!steamAccount.avatar)
        ?? player.discordAccounts.find((discordAccount) => !!discordAccount.avatar))?.avatar;
    },
    getFormattedDate(date: Date) {
      return formatDate(date);
    },
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    async flipSortDirection() {
      if (this.loading) {
        return;
      }

      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      await this.setPage(this.currentPage, this.searchTerm, this.sortDirection);
    },
    async setPage(page: number, searchTerm: string | undefined = undefined,
      sortDirection: SortDirection = 'desc') {
      if (this.loading || !this.currentOrg) {
        return;
      }

      let sanitizedPage;
      sanitizedPage = Math.max(page, 0);
      sanitizedPage = this.playersResponse?.totalPages
        ? Math.min(sanitizedPage, this.playersResponse.totalPages - 1)
        : Math.min(sanitizedPage, 0);

      if (sanitizedPage === this.currentPage && searchTerm === undefined) {
        return;
      }

      this.loading = true;

      this.playersResponse = await getPlayers(
        this.currentOrg.id, sanitizedPage, searchTerm, sortDirection,
      );

      this.currentPage = sanitizedPage;

      this.loading = false;
    },
  },
  async mounted() {
    this.appLoading = false;

    this.currentPage = 0;

    if (this.currentOrg) {
      this.playersResponse = await getPlayers(this.currentOrg.id, this.currentPage);
    }

    this.loading = false;
  },
});
